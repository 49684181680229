import gql from "graphql-tag";

export const ME = gql`
  query {
    me {
      langage {
        langageCode
        langageLabel
      }
    }
  }
`;
export const UPDATE_ADMIN = gql`
  mutation updateAdminAccount($input: AdminInput) {
    updateAdminAccount(input: $input) {
      id
    }
  }
`;
export const ME_ADMIN = gql`
  query {
    getAdminAccount {
      name
      photoUrl
      profile {
        email
        photoUrl
      }
    }
  }
`;
export const UPDATE_PASSWORD = gql`
  mutation updatePassword($input: PasswordInput) {
    updatePassword(input: $input) {
      id
    }
  }
`;
export const CHANAGE_LANGAGE = gql`
  mutation updateCurrentLangage($langage: LangageInput) {
    updateCurrentLangage(langage: $langage) {
      id
    }
  }
`;
export const SEND_ACCESS = gql`
  mutation sendWelcomeMail($id: String) {
    sendWelcomeMail(id: $id)
  }
`;
export const ACTIVE_USER = gql`
  mutation activeUser($userId: ID) {
    activeUser(userId: $userId)
  }
`;

export const ADD_STORE_MUTATION = gql`
  mutation addStore($input: StoreInput) {
    addStore(input: $input) {
      id
      name
      gerantName
      address
      photoUrl
      logoUrl
      location {
        latitude
        longitude
      }
      rating
      noticeCount
      profile {
        id
        email
        isBlocked
      }
      stripeInfos {
        companyName
        iban
        siren
        tva
      }
      responsible {
        firstName
        lastName
        address
        email
        tel
        birthDate
      }
      createdAt
    }
  }
`;

export const UPDATE_STORE_MUTATION = gql`
  mutation updateStore($input: StoreInputUpdate) {
    updateStore(input: $input) {
      id
    }
  }
`;
export const UPDATE_MY_PROFILE = gql`
  mutation updateMyStore($input: StoreInput) {
    updateMyStore(input: $input) {
      id
    }
  }
`;
export const UPDATE_MY_SETTING = gql`
  mutation updateMyStoreSettings($input: StoreInput) {
    updateMyStoreSettings(input: $input) {
      id
    }
  }
`;

export const STORE_FRAGMENT = gql`
  fragment planing on Florist {
    name
    appearsIn
    friends {
      name
    }
  }
`;

export const GET_STORE = gql`
  query getFlorist($id: ID) {
    getFlorist(id: $id) {
      id
      name
      webSite
      feeHostingPaid
      commercial
      address
      stripeReady
      stripeRequiredField
      stripeExist
      tel
      siret
      description
      photoUrl
      logoUrl
      types {
        id
        name
      }
      storeCountry {
        id
        name
        currency
      }
      closedDay {
        key
        startDay
        endDay
      }
      planing {
        monday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        tuesday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        wednesday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        thursday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        friday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        saturday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        sunday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
      }

      settings {
        paymentStripe
        paymentPaypal
        paymentCash
        showAddonParam
        addonStoreEnabled
        authorizeChatWithClient
        appLinkStore {
          linkAppClientAndroid
          linkAppClientIOS
          linkAppProAndroid
          linkAppProIOS
        }
        orderLifeTime
      }
      isMentionLegalAccepted
      group {
        id
        name
      }
      tags {
        id
        name
      }
      location {
        latitude
        longitude
      }
      zipCode
      city
      profile {
        email
      }
      stripeInfos {
        companyName
        iban
        siren
        tva
        sirenFile
      }
      responsible {
        firstName
        lastName
        address
        zipCode
        city
        email
        tel
        birthDate
        nidFront
        nidBack
        verifyHomeAddress
      }
    }
  }
`;

export const MY_PROFILe = gql`
  query {
    myStore {
      id
      name
      gerantName
      gerantEmail
      gerantTel
      webSite
      address
      tel
      siret
      description
      photoUrl
      settings {
        showAddonParam
        addonStoreEnabled
      }
      closedDay {
        key
        startDay
        endDay
      }
      planing {
        monday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        tuesday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        wednesday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        thursday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        friday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        saturday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
        sunday {
          firstSession {
            openAt
            closeAt
            work
          }
          secondSession {
            openAt
            closeAt
            work
          }
        }
      }
      location {
        latitude
        longitude
      }
      profile {
        email
      }
    }
  }
`;
export const GET_STORE_SUBSCRIPTION = gql`
  query getFlorist($id: ID) {
    data: getFlorist(id: $id) {
      id
      feeSubscription {
        status
        offer
        dueDate
      }
    }
  }
`;
export const GET_STORE_FEE_HOSTING = gql`
  query getFlorist($id: ID) {
    data: getFlorist(id: $id) {
      id
      feeHosting {
        acomptePaid {
          paid
          status
        }
      }
    }
  }
`;
export const UPDATE_STORE_SUBSCRITPION = gql`
  mutation updateSubscriptionStore($store: String, $input: SubscriptionInput) {
    updateSubscriptionStore(store: $store, input: $input) {
      id
    }
  }
`;

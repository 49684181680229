import gql from "graphql-tag";

export const ADD_PRODUCT_MUTATION = gql`
  mutation addProduct($productInput: ProductInput!) {
    addProduct(productInput: $productInput) {
      id
      name
      photoUrls {
        photoUrl
      }
    }
  }
`;
export const GET_ALL_PRODUCTS = gql`
  query getElasticProducts(
    $limit: Int
    $skip: Int
    $filter: ProductQueryInput
  ) {
    getProducts: getElasticProducts(
      limit: $limit
      skip: $skip
      filter: $filter
    ) {
      nodes {
        id
        name
        photoUrls {
          photoUrl
        }
        variations {
          id
          price
          weight
          refVariation
          product
          values {
            value
          }
        }
      }
      count
    }
  }
`;
export const ADD_PRODUCT_TO_MY_PRODUCTS = gql`
  mutation addProductToFlorist($input: ProductArrayIds!) {
    addProductToFlorist(input: $input)
  }
`;
export const IMPORT_PRODUCT = gql`
  mutation importProduct($input: ProductArrayIds!) {
    importProduct(input: $input)
  }
`;
export const REMOVE_PRODUCT_FROM_MY_PRODUCTS = gql`
  mutation removeProductToFlorist($id: ID!) {
    removeProductToFlorist(id: $id)
  }
`;
export const REMOVE_PRODUCTS = gql`
  mutation removeProducts($ids: [String]) {
    removeProducts(ids: $ids)
  }
`;
export const ACTIVATE_PRODUCTS = gql`
  mutation enablesProducts($ids: [String]) {
    enablesProducts(ids: $ids)
  }
`;
export const REMOVE_CLIENTS = gql`
  mutation removeClients($ids: [String]) {
    removeClients(ids: $ids)
  }
`;
export const SAVE_PRODUCT_PRICE = gql`
  mutation saveProductPrice($input: ProductFloristInput!) {
    saveProductPrice(input: $input)
  }
`;
export const ENABLE_PRODUCT = gql`
  mutation enableProduct($id: ID) {
    enableProduct(id: $id)
  }
`;
export const LOAD_PRODUCT_BY_ID = gql`
  query getProductById($productId: ID) {
    getProductById(productId: $productId) {
      id
      name
      subTitle
      additionalInformation
      refProduct
      description
      isChecked
      ean
      ref
      enableSimpleProduct
      enableMultiVariation
      defaultWeight
      minPriceCondition
      maxPriceCondition
      enableAddons
      editAddonsValue
      globalRating
      globalNoticeCount
      minPrice
      addons {
        id
        name
        multiQuantity
        defaultItems {
          id
          defaultPrice
          name
        }
      }
      addonsValue {
        id
        name
        multiSelect
        required
        minLength
        maxLength
        addon {
          id
          name
          multiQuantity
          defaultItems {
            id
            defaultPrice
            name
          }
        }
        items {
          name
          price
          addon
          itemId
          outOfStock
          checked
        }
      }
      createdBy {
        role
        isRequest
        refused
        refuseReason
        florist {
          id
          name
        }
      }
      variations {
        id
        price
        stockManaged
        stockValue
        weight
        values {
          label
          value
        }
      }

      optionColumns {
        defaultValue
        index
        required
        optionTemplate {
          id
          displayName
          label
          values
        }
      }
      categories {
        id
        name
      }
      subSubCategories {
        id
        name
        category {
          id
          name
          photoUrl
          rank
        }
        subCategory {
          id
          name
          photoUrl
          rank
        }
      }
      subCategories {
        id
        name
        category {
          id
          name
        }
      }
      theme {
        id
        name
      }
      photoUrls {
        photoUrl
        key
      }
      optionalShownColumns {
        id
        displayName
        label
      }
      labels {
        id
        name
      }
      mentions {
        id
        name
      }
    }
  }
`;
export const GET_ALL_CATEGORY = gql`
  query {
    getCategories {
      id
      name
      status
      photoUrl
    }
  }
`;
export const GET_ALL_THEME = gql`
  query {
    getThemes {
      id
      name
      photoUrl
      status
    }
  }
`;
export const UPDATE_PRODUCT_MUTATION = gql`
  mutation updateProduct($input: ProductInputUpdate) {
    updateProduct(input: $input) {
      id
    }
  }
`;

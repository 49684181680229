import config from "./config.json";
const bool = false;
const isLocal = bool;
const PROTOCOL = isLocal ? "http://" : "https://";
const WS_PROTOCOL = isLocal ? "ws://" : "wss://";
const HOST = isLocal ? "127.0.0.1:1993" : config.HOST;
const WS_ENDPOINT = "/subscriptions";
const GOOGLE_API_KEY = config.GOOGLE_API_KEY;
const STRIPE_ENDPOINT = "https://api.stripe.com/v1/tokens";
const STRIPE_DASHBORD = "https://dashboard.stripe.com/login";
const STRIPE_SECRET_KEY = "sk_test_X5KuJ3uuq3Ktjg3aQOjoLRdC00cPWoI5ts";
const STRIPE_PUPLIC_KEY = "pk_test_aZ6WqOrDWOMN8H5d3gjy0nyn00FAFiuUSj";
const BASE_URL = `${PROTOCOL}${HOST}`;
const PHOTO_URL = `${PROTOCOL}${HOST}`;
const WS_URL = `${WS_PROTOCOL}${HOST}${WS_ENDPOINT}`;
const DEFAULT_LANGAGE = "fr";
const APP_ID = config.APP_ID;
const DASH_URL = config.DASH_URL;
const SUPER_ADMIN_API = config.SUPER_ADMIN_API;
const APP_THEME = config.APP_THEME;
const App = {
  name: "LynkBooster",
  logo: "https://admin.flowerly.fr/img/logo-web.png",
};
const LANGUAGES = [
  {
    flag: "fr",
    langage: "Français",
    lang: "fr",
  },
  {
    flag: "US",
    langage: "English",
    lang: "en",
  },
];
//
const DeliveryApiTypes = ["STUART", "YPER", "CUSTOM", "OTHERS"];
const PAYMENT_APIS = { stripe: "stripe", paypal: "paypal" };
const UNIQUE_ADMIN_STORE_SHOW_MENU = ["discount", "delivery"];
const UNIQUE_ADMIN_STORE_HIDDEN_MENU = ["pros"];
export {
  BASE_URL,
  WS_URL,
  PHOTO_URL,
  GOOGLE_API_KEY,
  STRIPE_ENDPOINT,
  STRIPE_SECRET_KEY,
  STRIPE_PUPLIC_KEY,
  App,
  DEFAULT_LANGAGE,
  LANGUAGES,
  STRIPE_DASHBORD,
  DeliveryApiTypes,
  PAYMENT_APIS,
  UNIQUE_ADMIN_STORE_SHOW_MENU,
  UNIQUE_ADMIN_STORE_HIDDEN_MENU,
  APP_ID,
  DASH_URL,
  SUPER_ADMIN_API,
  APP_THEME,
};
